
const urlCreator = {
    signIn: () => '/api/v1/sign-in',
    getAllCalls: (companyId:number) => `/api/v1/companies/${companyId}/phone-calls`,
    getAllCallsFile: (companyId:number) => `/api/v1/companies/${companyId}/phone-calls/csv`,
    getProfile: () => `/api/v1/users/me`,
    getMyEmployee: (companyId:number) => `/api/v1/companies/${companyId}/me`,
    getCompanies: () => `/api/v1/companies/my`,
    getCallById: (companyId: number, callId: string) => `/api/v1/companies/${companyId}/phone-calls/${callId}`,
    getAllEmployees: (companyId: number,) => `/api/v1/companies/${companyId}/employees`,
    getCompanyBalance: (companyId: number) => `/api/v1/companies/${companyId}/balance`,
    getRequests: () => `/api/v1/onboarding/requests`,
    getChecklist: (companyId:number) => `/api/v1/companies/${companyId}/phone-calls/checklist`,

    patchRequestApprove: (requestId:number) => `/api/v1/onboarding/requests/${requestId}/approve`,
    patchChangeStatus: (requestId:number, status:string) => `/api/v1/onboarding/requests/${requestId}/${status}`,
    patchEditRequest: (requestId:number):string => `/api/v1/onboarding/requests/${requestId}`,

    postRefreshEmployees: () => `/api/v1/ria/employees`,
    postPasswordChange: () => `/api/v1/password-change`,
    postPasswordResetEmail: () => `/api/v1/password-reset`,
    patchCallStatus: (companyId:number, callId:string, status:string) => `/api/v1/companies/${companyId}/phone-calls/${callId}/${status}`,
    patchCallBatchStatus: (companyId:number, callIds:string[], status:string) => `/api/v1/companies/${companyId}/phone-calls/batch/${status}?${callIds.map((item, index) => {
        if(callIds.length -1 === index) return `phoneCallUuids=${item}`;
        return `phoneCallUuids=${item}&`;
    })}`,
    postCallLabel: (companyId:number, callId: string, labsegId: number) => `/api/v1/companies/${companyId}/phone-calls/${callId}/labseg/${labsegId}/labels`,
    deleteCallLabels: (companyId:number, callId: string, labsegId:number, labelIds: number[]) => `api/v1/companies/${companyId}/phone-calls/${callId}/labseg/${labsegId}/labels/batch?${labelIds.map((item, index) => {
        return index === labelIds.length - 1 ? `labelIds=${item}` : `labelIds=${item}&`;
    })}`,

    putCallLabseg: (companyId: number, phoneCallUuid:string, labsegId:number, labelId:number) => `/api/v1/companies/${companyId}/phone-calls/${phoneCallUuid}/labseg/${labsegId}/labels/${labelId}`,

    putChangeMyPassword: (oldPassword:string, newPassword:string, confirmNewPassword:string) => `/api/v1/users/me/password?oldPassword=${oldPassword}&newPassword=${newPassword}&confirmNewPassword=${confirmNewPassword}`,
    putChangeMyPasswordP: () => `/api/v1/users/me/password`,
    patchDecodingCall: (companyId:number, callId: string) => `/api/v1/companies/${companyId}/phone-calls/${callId}/retryDecoding`,
    patchCallNotes: (companyId: number, phoneCallUuid:string, notes:string) => `/api/v1/companies/${companyId}/phone-calls/${phoneCallUuid}/notes?notes=${notes}`,

    postNewLead: () => `/public-api/v1/leads/onboarding-requests`,

    postCalls: (companyId:number, employeeId: number, encodedPhoneNumber:string, phoneTime:string) => `/api/v1/companies/${companyId}/employees/${employeeId}/phone-calls?phoneNumber=${encodedPhoneNumber}&phoneTime=${phoneTime}`

};

export default urlCreator;
