import React, {forwardRef, useEffect} from 'react';
import {ICallChecklistResource, IColumnChecklistResource} from "../../../../resources/ICallChecklist";
import ClickCopyBtn from "../../../AutomaticAssessmentPage/components/ClickCopyBtn";
import TdDate from "../../../AutomaticAssessmentPage/components/TdDate";
import DateService from "../../../../services/DateService";
import HeadFilter, {EnumSortType} from "../../../AutomaticAssessmentPage/components/HeadFilter";
import HeadFilterYesNo from "../HeadFilterYesNo";
import styles from './styles.module.scss';
import {NavLink} from "react-router-dom";

const LinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 8C2 7.72386 2.22386 7.5 2.5 7.5H4.5C4.77614 7.5 5 7.72386 5 8C5 8.27614 4.77614 8.5 4.5 8.5H2.5C2.22386 8.5 2 8.27614 2 8Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.5 8C6.5 7.72386 6.72386 7.5 7 7.5H13.5C13.7761 7.5 14 7.72386 14 8C14 8.27614 13.7761 8.5 13.5 8.5H7C6.72386 8.5 6.5 8.27614 6.5 8Z" fill="#1E1E1E"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.1464 4.64645C10.3417 4.45118 10.6583 4.45118 10.8536 4.64645L13.8536 7.64645C14.0488 7.84171 14.0488 8.15829 13.8536 8.35355L10.8536 11.3536C10.6583 11.5488 10.3417 11.5488 10.1464 11.3536C9.95118 11.1583 9.95118 10.8417 10.1464 10.6464L12.7929 8L10.1464 5.35355C9.95118 5.15829 9.95118 4.84171 10.1464 4.64645Z" fill="#1E1E1E"/>
        </svg>
    )
}

interface IProps {
    data: ICallChecklistResource[];
    selectedSort: string;
    handleSelectSort: (value:string) => void;
    handleOpenFilterModal: () => void;
    filter?: string;
}

const ChecklistTable = forwardRef(({data, handleSelectSort, selectedSort, filter, handleOpenFilterModal}:IProps, ref:any) => {
    const handleScroll = (e:React.UIEvent<HTMLElement>) => {
        const scrollTop = e.currentTarget.scrollTop;
        sessionStorage.setItem('scroll', JSON.stringify(scrollTop));
    };

    const handleScrollTo = () => {
        const scroll = sessionStorage.getItem('scroll');
        if(!scroll) return;
        ref?.current?.scrollTo(0, Number(scroll))
    }

    useEffect(() => {
        handleScrollTo();
    },[data])

    return (
        <>
            <div
                className={styles.table__container}
                id={'table-container'}
                ref={ref}
                onScroll={handleScroll}
            >
                <table
                    className={styles.table}
                    cellSpacing={0}
                    cellPadding={0}
                >
                    <thead className={styles.table__thead}>
                    <tr>
                        <td className={styles.table__cell_first}>
                            ID
                        </td>
                        <td className={styles.table__cell_head}>
                            <div style={{width: 140}}>
                                <HeadFilter
                                    selectedSort={selectedSort}
                                    handleSelectSort={handleSelectSort}
                                    title={'Дата та час'}
                                    type={EnumSortType.dateCreate}
                                />
                            </div>
                        </td>
                        <td className={styles.table__cell_head}>
                            <div style={{width: 120}}>
                                <HeadFilter
                                    selectedSort={selectedSort}
                                    handleSelectSort={handleSelectSort}
                                    title={'Оператор'}
                                    type={EnumSortType.analyticsOperator}
                                    filter={filter}
                                    isFilter={true}
                                    handleOpenFilterModal={handleOpenFilterModal}
                                />
                            </div>
                        </td>
                        <td className={styles.table__cell_head}>
                            Телефон
                        </td>
                        <td className={styles.table__cell_head}>
                            <div style={{width: 130}}>
                                <HeadFilter
                                    selectedSort={selectedSort}
                                    handleSelectSort={handleSelectSort}
                                    title={'Середній бал'}
                                    type={EnumSortType.analyticsProblemResult}
                                />
                            </div>
                        </td>
                        <td className={styles.table__cell_head}>
                            <HeadFilter
                                selectedSort={selectedSort}
                                handleSelectSort={handleSelectSort}
                                title={'Час в роботі'}
                                type={EnumSortType.analyticsDuration}
                            />
                        </td>
                        {data[0]?.columns.map((item:IColumnChecklistResource) => (
                            <td
                                className={styles.table__cell_head}
                                key={item.columnName}
                            >
                                <div style={{width: 320}}>
                                    <HeadFilterYesNo
                                        title={item.data.question}
                                        columnName={item.columnName}
                                    />
                                </div>
                            </td>
                        ))}
                        <td style={{width: 50}} className={styles.table__cell_last}></td>
                    </tr>
                    </thead>
                    <tbody className={styles.table__tbody}>
                    {data.map((item: ICallChecklistResource) => (
                        <tr key={item.phoneCall.uuid}>
                            <td>
                                <ClickCopyBtn text={item.phoneCall.uuid}/>
                            </td>
                            <td>
                                {DateService.formatUI(item.phoneCall.created, 'DD.MM.YYYY HH:mm')}
                            </td>
                            <td>
                                {item.phoneCall.employee.fullName}
                            </td>
                            <td>
                                {item.phoneCall.phoneNumber}
                            </td>
                            <td>
                                <div className={styles.table__cell_question}>
                                    {item.phoneCall.problemResult ? item.phoneCall.problemResult : '-'}
                                </div>
                            </td>
                            <td>
                                <TdDate
                                    duration={item.phoneCall.duration}
                                />
                            </td>
                            {item.columns.map((unit:IColumnChecklistResource) => (
                                <td key={unit.columnName}>
                                    <div className={styles.table__cell_question}>
                                        {unit.data.value === 1 ? 'Так' : 'Ні'}
                                    </div>
                                </td>
                            ))}
                            <td>
                                <NavLink
                                    to={`/call/${item.phoneCall.uuid}`}
                                >
                                    <LinkIcon />
                                </NavLink>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
});

export default ChecklistTable;
